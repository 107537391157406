//
// Dark Header(used by Dark Header layout only)
//

[data-kt-app-layout="dark-header"] {
    .app-header {
        background-color: $app-header-dark-bg-color;
        border-bottom: 0 !important;

        .btn-custom {
            @include button-custom-variant(
                $color:#B5B5C3, 
                $icon-color: #B5B5C3, 
                $border-color: null, 
                $bg-color: null, 
                $color-active: #B5B5C3, 
                $icon-color-active: var(--kt-primary), 
                $border-color-active: null, 
                $bg-color-active: rgba(63, 66, 84, 0.35)
            );
        }
    }

    // Desktop mode
    @include media-breakpoint-up(lg) {
        // General mode
        .app-header-menu {
            .menu {                  
                // Menu root links          
                > .menu-item {
                    @include menu-link-default-state( 
                        $title-color: #CBCBCB,
                        $icon-color:#C5C5D8, 
                        $bullet-color:#787887, 
                        $arrow-color: #787887, 
                        $bg-color: null,
                        $all-links: false
                    );

                    @include menu-link-hover-state( 
                        $title-color: var(--kt-primary-inverse), 
                        $icon-color: var(--kt-primary-inverse), 
                        $bullet-color: var(--kt-primary-inverse), 
                        $arrow-color: var(--kt-primary-inverse), 
                        $bg-color: $app-sidebar-dark-menu-link-bg-color-active,
                        $all-links: false
                    );

                    @include menu-link-show-state( 
                        $title-color: var(--kt-primary-inverse), 
                        $icon-color: var(--kt-primary-inverse), 
                        $bullet-color: var(--kt-primary-inverse), 
                        $arrow-color: var(--kt-primary-inverse), 
                        $bg-color: null,
                        $all-links: false
                    );

                    @include menu-link-here-state( 
                        $title-color: var(--kt-primary-inverse), 
                        $icon-color: var(--kt-primary-inverse), 
                        $bullet-color: var(--kt-primary-inverse), 
                        $arrow-color: var(--kt-primary-inverse), 
                        $bg-color: $app-sidebar-dark-menu-link-bg-color-active,
                        $all-links: false
                    );   
                    
                    @include menu-link-active-state( 
                        $title-color: var(--kt-primary-inverse), 
                        $icon-color: var(--kt-primary-inverse), 
                        $bullet-color: var(--kt-primary-inverse), 
                        $arrow-color: var(--kt-primary-inverse), 
                        $bg-color: $app-sidebar-dark-menu-link-bg-color-active,
                        $all-links: false
                    );
                }
            }
        }
    }
}

