div.loader {
  position: fixed;
  /*display: none;*/
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.70);
  z-index: 1111;
  cursor: default;
}
.loader_inner img {
  width: 100px;
  height: 100px;
}
.loader_inner {
  text-align: center;
}
.loader_inner p {
  text-align: center;
  margin: 0;
  color: rgb(7, 163, 72);
  font-size: 16px;
  margin-top: -40px;
}
div.loader > div {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 9;
}