.login_card,
.registration_card {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_form,
.registration_form {
  width: 40%;
}

.registration_form {
  width: 50%;
}

.aside-logo {
  height: 120px !important;
}

.logo {
  position: absolute;
  background: #fff;
  padding: 10px;
  left: 25px;
  top: 30px;
  z-index: 9;
  width: 190px;
  height: auto !important;
}

.template-sections {
  .accordion-button:not(.collapsed) {
    background-color: var(--white);
  }
}

.logo:hover {
  text-decoration: none;
  color: inherit;
}

.logo img {
  filter: invert(1);
}

.background_card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 40%;
  }
}

.btn-pill {
  border-radius: 2rem;
}

.btn-outline-primary:hover {
  color: $white;
}

.invalid-feedback,
.fv-help-block {
  font-size: 13px !important;
}

.custom_input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #e4e6ef;
  padding-left: 0;
}

.custom_input:focus {
  box-shadow: none;
  border-color: inherit;
}

.custom_input {
  font-size: 14px;
}

.forgot_password {
  max-width: 400px;
  margin: 0 auto;
}

.form-control {
  font-weight: normal !important;
}

label {
  font-size: 1.1rem;
}

/*input[type="file"] {
  padding: 0.4rem 0.7rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}*/

.close_remove_btn {
  //position: absolute;
  //right: 8px;
  //top: 6px;
  float: right;
  margin-bottom: 5px;
  margin-right: 2px;
  z-index: 1;
  cursor: pointer;
}

.close_btn {
  top: -8px;
  right: 0;
  z-index: 1;
  cursor: pointer;
}

.vertical_center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.vertical-align-middle {
  vertical-align: middle;
}
.vertical-align-top {
  vertical-align: top;
}

.cursor-disabled {
  cursor: not-allowed;
}

.gutter-b {
  margin-bottom: 25px;
}

.fa-15x {
  font-size: 1.5em;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 43px !important;
}

.w-15 {
  width: 15% !important;
}
.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.font-size-h6 {
  font-size: 1.175rem !important
}

.form-group {
  margin-bottom: 1.75rem;
}

.font-weight-bold {
  font-weight: 500 !important;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

select.appearance {
  appearance: auto;
}

.card {
  border-radius: 0.8rem !important;
  box-shadow: 0 0 5px #1718180d, 0 1px 2px #17181826 !important;
}

.toastify-info {
  background: linear-gradient(135deg, #73a5ff, #5477f5);
}

.toastify-error {
  background: linear-gradient(135deg, #ff5f6d, #ffc371) !important;
}

.manage-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-daterange-picker__wrapper, .react-date-picker__wrapper, .react-time-picker__wrapper {
  //height: calc(1.5em + 1.3rem + 2px);
  //padding: 0.65rem 1rem;
  //border: 1px solid #E4E6EF !important;
  //border-radius: 0.42rem;
  border: 0 !important;
}

.react-date-picker__button, .react-time-picker__button {
  padding: revert !important;
}

.react-date-picker__button__icon, .react-time-picker__button__icon, .react-daterange-picker__button__icon {
  stroke: #757575;
}

.react-daterange-picker__range-divider {
  flex: auto;
}

.user-avatar-img {
  width: 50px;
  height: 50px;
}

.border-right-radius-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.border-left-radius-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.company-users__menu {
  z-index: 9 !important;
}

.bottom-sheet {
  div:nth-child(1) {
    z-index: 999;
  }

  div:nth-child(2) {
    max-width: 640px;
    margin: auto;
    z-index: 999;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
  }
}

.user-company-list {
  max-width: 250px;
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.time_picker_UI, .date_picker_UI {
  .MuiFormControl-marginNormal {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    width: 100%;
  }
}

.date_picker_UI {
  &.w-150px {
    .MuiButtonBase-root {
      padding: 0 !important;
    }
  }
}

.MuiInputBase-input {
  padding: 3px 0 !important;
}

.background-green {
  background-color: #e3f0ee;
}

.d-list-item {
  display: list-item !important;
}

button.fc-today-button, button.fc-dayGridMonth-button, button.fc-timeGridWeek-button, button.fc-timeGridDay-button {
  text-transform: uppercase !important;
}

#react-select-2-listbox {
  z-index: 10;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1500px !important;
  }
}

@media screen and (max-width: 992px) {
  .d-flex .flex-column-fluid {
    padding-top: 15px !important;
  }
}

@media screen and (max-width: 1024px) {
  .login_form,
  .registration_form {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .background_card,
  .hide_on_mobile {
    display: none;
  }

  .show_on_mobile {
    display: block !important;
    position: absolute;
    top: 50px;
  }
  .login_form,
  .registration_form {
    width: 90%;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .login_form,
  .registration_form {
    width: 60%;
  }
}

.form-control:disabled {
  cursor: not-allowed;
  //background-color: #EFF2F5 !important;
}

.text-strikethrough {
  text-decoration: line-through;
}

.react-calendar {
  border: 1px solid #e4e6ef !important;
  border-radius: 0.42rem !important;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.fc-event-main-frame {
  color: #000000;
}


.text-success.active {
  padding: 1px 2px;
  border: 1px solid $success;
}

.text-primary.active {
  padding: 1px 2px;
  border: 1px solid $primary;
}

.text-info.active {
  padding: 1px 2px;
  border: 1px solid $info;
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 60px !important;
}

.react-tel-input .form-control {
  width: inherit;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--kt-primary);
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0 2.25rem;
}

iframe {
  z-index: -1 !important;
}

.filepond--wrapper {
  .filepond--credits {
    display: none !important;
  }

  .filepond--item {
    width: calc(25% - 0.5em);
  }
}

.long-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.spinnerCenter {
  .smoothImageRender {
    transition: opacity 1s;
  }

  .imageRenderVisible {
    opacity: 1;
  }

  .imageRenderHidden {
    opacity: 0;
  }

  .spinner-border {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -16px 0 0 -16px;
  }
}

//Image Remove Icon
.docItembox {
  .removeDocitem {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    background: $primary-light-dark;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: $white;
    cursor: pointer;
    opacity: 0;
    transition: all 0.2s linear;

    &:hover {
      color: $primary-light-dark;
      background: $danger;
    }
  }

  &:hover {
    .removeDocitem {
      opacity: 1;
    }
  }
}

.form-check-input {
  &.stop-sales {
    position: relative;

    &[type="checkbox"] {
      &:checked:after {
        content: "X";
        color: $white;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    &:checked {
      &[type="checkbox"] {
        background-image: none;
      }
    }
  }
}

table {
  td {
    .allotment-input {
      &.form-control {
        border-radius: 0 !important;
        padding: 0 2px !important;
      }

      &:disabled {
        color: var(--kt-input-disabled-color) !important;
      }
    }
  }
}

.react-select-container {
  .react-select__input-container, .react-select__single-value {
    color: var(--kt-input-color) !important;
  }
  .react-select__control{
    color: var(--kt-input-color);
    background-color: var(--kt-input-bg);
    border: 1px solid var(--kt-input-border-color);
    box-shadow: none !important;
  }
  .react-select__control, .react-select__menu {
    background-color: var(--bs-body-bg) !important;
  }
  .react-select__indicator-separator{
    width: 0 !important;
  }

  .react-select__menu-list {
    .react-select__option {
      &:hover, &:focus {
        background-color: var(--kt-dropdown-link-hover-bg) !important;
      }
    }
  }
}

.MuiFormControl-root {
  .MuiInputBase-input {
    &.MuiInput-input {
      color: var(--kt-input-color) !important;
    }
  }

  .MuiIconButton-root {
    color: var(--kt-input-color) !important;
  }
}

.cke {
  .cke_top {
    background-color: var(--bs-body-bg) !important;
  }
}

.react-tel-input {
  .form-control {
    background-color: var(--bs-body-bg) !important;
  }

  .country-list, .search {
    background-color: var(--bs-body-bg) !important;

    .country {
      &:hover {
        background-color: var(--kt-dropdown-link-hover-bg) !important;
      }
    }

    .highlight {
      background-color: var(--kt-text-gray-500) !important;
    }
  }
}

.MuiPagination-ul {
  .MuiPaginationItem-page {
    background-color: var(--kt-light);
    color: var(--kt-text-dark);
  }

  //&.MuiButtonBase-root, &.MuiPaginationItem-root {
  //
  //}
}

[data-theme='dark'] {
  .react-select-container {
    .react-select__option {
      &.css-d7l1ni-option {
        background-color: #686868 !important;
      }
    }
  }
}

.table-revert {
  tr {
    display: inline-flex;
    flex-direction: column;
  }
}

//For star rating
.stars-rating {
  display: inline-block;
  font-size: 25px;
  line-height: 1;

  &::before {
    content: '★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, #fc0 100%, #fff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.form-group {
  position: relative;
  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
}
.booking-tabs{
  &:hover{
    background-color: var(--bs-body-bg);
  }
}
.topbar-badge {
  right: -9px;
  top: 4px!important;
}
.category-prices-modal{
  &:hover{
    font-weight: 600;
    background-color: var(--bs-light);
  }
}

.custom-table-padding {
  th, td {
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
  }
}

.table-fixed thead th:first-child,
.table-fixed tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}

.btn--glow {
  position:relative;
  overflow:hidden;
}

.btn--glow:before{
  content:'';
  position: absolute;
  left: -20%;
  top:-10%;
  width: 0;
  height: 100%;
  transform: rotate(15deg) scale(1, 2);
  box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.7);
  animation: button-glow 3000ms ease-in-out infinite;
}

@keyframes button-glow {
  0% {
    left: -20%;
  }
  50% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}

.btn-ripple {
  position: relative;
  overflow: hidden;
}

.btn-ripple::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  padding-top: 200%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.6s, transform 0.6s;
}

.btn-ripple:hover::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.6s, opacity 0.6s;
}

.btn-pulse {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-pulse::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease-out, opacity 1s;
  z-index: -1;
}

.btn-pulse:hover::before {
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
}

@media print {
  .keep-together {
    break-inside: avoid;
  }
}